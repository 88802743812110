<svg class="tumbleweed" viewBox="0 0 864 864">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M135.708,444.458c8.388-35.936,12.715-73.495,26.265-107.365
      c14.631-36.572,14.588-70.059-0.094-105.141c-22.187,24.956-18.151,64.412-42.341,88.701c-2.36-0.824-4.722-1.647-7.084-2.47
      c1.561-9.194,1.935-18.797,4.936-27.493c5.481-15.877,12.944-31.063,18.617-46.884c1.479-4.12,0.055-9.697-1.186-14.294
      c-2.751-10.208-6.231-20.218-9.415-30.31c1.54-0.53,3.078-1.062,4.618-1.593c3.541,8.654,7.081,17.309,11.146,27.245
      c10.524-9.235,16.988-19.268,14.819-34.083c-1.251-8.557-2.044-17.687-0.299-25.981c1.008-4.794,7.472-8.438,11.481-12.599
      c2.076,4.886,4.661,9.636,6.036,14.711c0.809,2.984-0.304,6.491-0.924,14.662c6.443-8.462,9.312-14.277,13.958-17.646
      c3.411-2.477,9.148-1.749,13.848-2.449c-0.25,4.132,0.798,8.969-0.993,12.264c-5.242,9.651-12.22,18.371-17.302,28.09
      c-2.791,5.337-3.135,11.955-1.818,19.521c6.747-25.736,24.805-38.154,47.06-46.419c-19.169,21.787-46.64,38.99-38.1,77.069
      c3.664-2.408,4.536-2.681,4.58-3.05c4.288-36.449,33.766-45.067,61.406-56.009c25.352-10.036,25.092-10.273,14.752-34.646
      c-3.791-8.932-6.477-18.333-9.667-27.519c1.362-1.796,2.725-3.593,4.085-5.391c6.204,3.29,14.34,5.141,18.127,10.229
      c4.861,6.53,6.223,15.662,9.523,24.875c24.212-29.992,47.072-58.309,72.34-89.608c17.234,12.259,8.567,37.421,21.274,52.466
      c4.671-0.943,9.542-1.928,14.413-2.913c-24.258,15.357-9.364,32.462-2.757,48.534c4.985,12.125,11.283,23.711,16.998,35.536
      c1.803-0.084,3.606-0.167,5.409-0.25c1.747-18.625,3.066-37.303,5.356-55.862c2.432-19.7,5.686-39.303,8.853-58.904
      c0.413-2.559,2.455-4.854,3.742-7.271c4.7,2.531,9.4,5.062,15.826,8.525c15.141-10.328,24.675-35.478,52.756-28.242
      c-34.274,3.95-42.285,32.645-56.684,55.612c21.637-5.657,37.461-25.788,63.384-22.971c-17.321,9.564-33.262,17.188-47.929,26.762
      c-18.403,12.014-24.277,32.017-27.656,52.196c-3.512,20.959-5.173,42.229-8.095,67.225c10.162-5.893,18.485-10.719,26.806-15.545
      c1.321,1.111,2.643,2.223,3.965,3.334c-7.376,7.848-14.751,15.696-21.744,23.137c5.726,11.339,11.415,22.607,17.104,33.876
      c-1.768,1.352-3.533,2.704-5.298,4.056c-17.489-0.502-19.258-21.754-32.346-28.064c-16.282,21.122,2.611,44.17,50.48,58.483
      c-2.359-36.288-3.798-71.479-7.373-106.449c-1.267-12.396,2.148-17.012,12.17-24.333c18.429-13.459,34.103-30.889,50.042-47.508
      c18.948-19.756,33.677-44.086,66.822-56.48c-3.602,8.834-4.411,15.39-8.091,19.107c-23.736,23.976-47.483,48.021-72.571,70.547
      c-9.65,8.668-7.41,17.718-5.665,26.682c2.486,12.778,7.285,25.106,12.16,41.114c8.844-18.611,18.423-34.06,23.398-50.871
      c3.391-11.462,19.153-20.739,8.81-36.039c-0.376-0.557,7.478-9.15,11.992-9.67c2.982-0.342,8.786,6.546,10.027,11.097
      c10.738,39.394,20.715,78.996,30.965,118.522c0.974,3.753,2.27,7.422,3.414,11.129c1.897,0.417,3.796,0.834,5.693,1.25
      c14.661-43.792,31.403-87.03,43.283-131.565c8.438-31.641,0.527-45.925-23.161-64.479c1.021-1.262,2.044-2.524,3.065-3.788
      c8.783,7.111,17.567,14.221,26.162,21.18c3.773-1.649,9.034-3.948,14.295-6.247c0.408,5.579,1.295,11.174,1.105,16.733
      c-0.256,7.475-1.345,14.921-2.056,22.192c29.045,10.986,14.684,41.008,27.728,60.765c15.107-2.456,30.943-5.503,46.918-7.327
      c4.519-0.516,9.445,2.546,14.185,3.968c-3.746,3.698-6.93,9.509-11.346,10.685c-11.755,3.132-23.981,5.004-36.133,5.992
      c-12.687,1.032-15.335,10.314-8.13,15.79c29.345,22.306,21.599,54.709,23.77,83.44c1.853,24.507-2.855,49.511-5.086,80.108
      c14.351-21.771,27.037-39.599,38.144-58.36c7.657-12.936,12.646-27.427,19.854-40.665c2.258-4.148,7.854-6.479,14.155-7.87
      c-8.41,47.188-41.563,80.843-65.37,124.259c18.635-2.799,34.847-7.739,50.743-6.871c13.884,0.759,31.516-9.164,44.091,10.396
      c-29.357-5.629-35.343,13.337-44.457,33.084c-13.327,28.874-31.052,55.718-46.783,83.22c10.504,3.666,28.124-2.393,39.125-14.328
      c9.005-9.77,19.167-13.286,36.066-5.716c-15.89,9.316-28.384,16.644-40.881,23.97c0.202,1.495,0.401,2.991,0.604,4.488
      c19.336,1.98,38.705,3.71,57.987,6.127c4.543,0.568,8.797,3.452,13.183,5.271c-4.006,3.468-7.5,8.42-12.15,10.025
      c-5.841,2.017-12.663,1.943-19.004,1.62c-15.458-0.786-26.781,2.741-24.46,24.54c-11.432-11.777-1.897-35.575-25.019-33.968
      c-9.007,0.626-17.945,2.234-26.914,3.395c0.023,1.741,0.048,3.483,0.072,5.227c3.774,3.092,7.549,6.185,12.588,10.315
      c-10.619,2.574-19.904,4.824-27.603,6.689c8.086,34.859,16.198,69.824,24.31,104.791c-2.69,0.964-5.38,1.928-8.071,2.894
      c-5.447-13.389-10.894-26.775-17.547-43.128c-7.125,9.485-13.29,17.692-19.454,25.899c-1.08-0.521-2.16-1.043-3.24-1.563
      c4.05-8.938,6.75-18.868,12.439-26.599c10.699-14.533,5.642-28.729,0.567-42.495c-1.621-4.4-9.628-9.274-14.312-8.902
      c-4.404,0.353-10.659,6.73-11.89,11.536c-2.559,9.994-2.123,20.728-3.326,31.109c-0.818,7.077-2.331,14.074-3.533,21.107
      c-1.572-0.154-3.145-0.307-4.717-0.458c0-15.621,0-31.242,0-47.448c-40.321,23.237-77.877,47.176-117.474,67.047
      c-38.402,19.274-78.901,34.371-118.479,51.302c0.55,2.405,1.099,4.812,1.647,7.216c34.522-3.833,69.236-6.45,103.515-11.844
      c34.357-5.403,68.279-13.58,104.195-20.91c1.294,6.059,2.283,10.693,2.985,13.983c-59.79,17.979-112.217,57.066-177.306,47.471
      c0.162,29.266-11.11,41.768-33.039,39.405c0-6.696,0-13.484,0-22.819c-26.932-10.49-56.701-18.105-82.05-33.162
      c-22.016-13.079-35.76-3.168-53.643,4.933c-20.708,9.379-41.843,20.229-70.385,16.634c12.264-22.971,38.088-9.391,49.019-22.677
      c-9.162-1.022-19.425-2.169-29.689-3.314c0.068-1.953,0.139-3.908,0.208-5.862c12.49-2.047,24.979-4.095,37.469-6.144
      c0.444-2.007,0.89-4.017,1.334-6.022c-23.684-11.718-47.368-23.435-74.976-37.092c7.228-2.122,9.978-2.931,13.486-3.961
      c-23.486-19.475-46.238-39.186-70.066-57.492c-5.781-4.442-15.021-4.873-22.836-6.021c-6.378-0.937-13.002-0.189-19.517-0.189
      c-0.185-1.768-0.368-3.534-0.552-5.303c5.324-1.795,10.646-3.59,15.976-5.388c-0.07-1.244,0.308-2.92-0.254-3.343
      c-38.712-29.013-60.538-71.419-87.335-109.877c-5.62-8.067-12.656-15.438-16.646-24.218c-1.877-4.127,1.926-10.833,3.153-16.369
      c4.045,2.815,9.154,4.835,11.96,8.58c14.947,19.949,30.575,39.556,43.63,60.722c27.549,44.668,67.292,76.107,108.633,106.649
      c25.121,18.559,47.93,40.243,71.831,60.461c3.808,3.222,7.896,6.111,11.763,9.085c11.004-11.37,5.481-19.411-0.451-29.158
      c-16.807-27.609-32.291-56.022-51.049-88.953c-2.118,10.625-3.436,17.233-5.585,28.016c8.971,12.676,25.261,35.697,41.552,58.716
      c-1.043,1.148-2.085,2.297-3.128,3.447c-3.876-2.463-8.343-4.325-11.534-7.479c-24.786-24.51-51.688-47.409-73.113-74.604
      c-14.195-18.019-20.358-42.342-30.251-63.778c-16.617-36.005-24.402-74.371-32.612-112.904
      c-4.348-20.41-16.319-39.106-23.593-59.034c-2.399-6.577-3.756-19.101-0.603-21.121c10.538-6.752,12.717,4.784,16.417,11.48
      c1.625,2.943,2.782,6.146,4.272,9.5c8.164-7.176,15.599-13.712,23.035-20.249c1.126,1.33,2.254,2.661,3.38,3.991
      c-4.85,4.594-11.315,8.373-14.151,13.985c-3.739,7.404-7.776,16.938-6.151,24.342c4.809,21.91,12.268,43.244,18.941,64.726
      c0.502,1.619,2.984,2.624,4.497,3.98c2.704,10.415,5.457,20.764,8.21,31.113c15.65-37.771,15.65-37.771,27.844-34.064
      c-20.666,22.39-23.2,56.979-6.087,78.341c5.768-16.043,11.227-32.484,17.553-48.583c19.287-49.082,30.182-98.342,4.799-150.273
      c-2.586,4.001-5.732,7.005-6.646,10.581c-6.447,25.193-11.842,50.669-18.831,75.705c-3.228,11.567-8.639,22.698-14.467,33.25
      C146.75,443.061,140.057,443.111,135.708,444.458z
      M411.684,428.855c-9.887,3.028-20.852,6.388-31.819,9.75
      c-1.489-2.534-2.979-5.068-4.468-7.603c6.601-5.614,12.81-11.786,19.87-16.747c33.57-23.598,41.832-33.941,20.214-67.873
      c-21.711,15.653-50.261,86.746-66.796,142.316c13.259-4.713,24.513-8.714,35.765-12.715c1.007,1.246,2.012,2.493,3.017,3.739
      c-5.368,3.948-10.251,9.005-16.207,11.609c-11.812,5.161-28.875,4.358-32.093,19.846c-7.398,35.614-22.005,71.398-2.36,108.511
      c7.554-10.658,15.518-21.894,23.482-33.128c1.41,0.662,2.818,1.326,4.229,1.989c-13.922,25.238-38.753,48.401-15.096,82.967
      c4.363-17.214,9.186-32.438,11.975-48.026c5.833-32.598,24.621-66.871-10.502-95.4c-2.157-1.751,0.116-8.955,0.328-13.621
      c4.666,0.398,9.479,0.232,13.944,1.396c2.869,0.749,5.229,3.456,8.974,6.104C387.046,489.962,399.37,459.396,411.684,428.855z
      M525.096,552.438c39.132-9.128,31.617-9.359,32.376-38.953c0.55-21.511,5.663-28.313,30.139-33.199
      c-29.824,12.833-22.854,36.927-22.253,59.577c21.189-2.878,36.72-23.26,37.07-49.376c0.544-40.736,0.367-81.487,0.043-122.227
      c-0.05-6.308-2.513-12.599-3.861-18.897c-1.698-0.15-3.396-0.301-5.094-0.45c-8.38,22.337-16.758,44.676-25.433,67.805
      c6.108,0,9.849,0,11.73,0c-12.118,15.245-24.206,30.45-38.966,49.017c11.035-1.146,16.034-1.663,21.035-2.183
      c1.119,1.241,2.24,2.481,3.359,3.721c-3.325,3.695-6.052,9.636-10.078,10.711c-22.257,5.94-33.885,22.202-40.699,36.501
      C518.762,529.82,521.927,541.12,525.096,552.438z
      M461.972,492.582c1.196,1.146,2.395,2.293,3.593,3.438
      c25.574-30.558,51.149-61.115,77.751-92.898c-3.97,32.181-28.575,52.19-43.823,77.896c15.293-1.314,23.219-8.118,27.709-22.151
      c5.469-17.089,13.637-33.393,21.498-49.619c12.404-25.6,26.012-50.626,37.91-76.45c2.197-4.773,0.794-13.828-2.559-18.013
      c-8.018-10.008-17.831-18.704-27.59-27.155c-9.613-8.325-20.052-15.696-32.771-25.529c-8.795,26.767,0.337,48.328,4.84,70.703
      c6.161-8.085,9.973-17.092,15.496-24.887c2.27-3.203,8.226-3.792,12.505-5.572c0.918,3.924,3.16,8.106,2.458,11.714
      c-1.162,5.968-4.224,11.567-6.418,17.157c5.377,2.23,9.955,4.13,13.546,5.621c-15.477,12.165-30.513,23.981-45.547,35.799
      c0.948,1.243,1.896,2.485,2.845,3.726c10.446,1.166,20.89,2.329,31.334,3.493C496.227,391.953,481.321,411.636,461.972,492.582z
      M577.783,556.874c-29.56,13.556-60.885,14.471-79.77,39.018c-18.896,24.565-41.976,33.644-72.391,29.568
      c-7.817-1.048-19.823,3.079-24.472,9.054c-18.69,24.022-35.249,49.703-52.555,74.713c35.861-5.236,52.117-29.544,62.548-61.049
      c0.617,5.885,1.234,11.771,1.851,17.656c1.4,1.126,2.8,2.252,4.199,3.381c3.584-5.197,7.033-10.492,10.775-15.571
      c12.666-17.185,32.291-16.8,48.927-13.601c11.974,2.304,17.853,0.528,25.104-7.375c5.396-5.877,11.666-11.558,18.641-15.271
      C544.91,604.469,562.822,585.707,577.783,556.874z
      M665.589,396.433c12.849-29.089,22.739-56.964,13.231-88.347
      c2.492,3.813,4.985,7.624,8.007,12.247c5.808-13.784,5.808-13.784-15.778-65.477c-37.471,1.641-48.267,30.108-52.665,58.87
      c-1.777,11.616,12.437,25.678,19.373,38.627c1.87-0.456,3.739-0.913,5.609-1.369c1.403-7.672,5.163-15.886,3.559-22.867
      c-1.669-7.262-8.52-13.332-14.927-22.544c7.979,3.657,13.443,6.161,20.106,9.213c4.324-10.897,8.622-21.732,12.922-32.566
      c1.675,0.292,3.349,0.584,5.023,0.875C660.063,320.158,638.736,357.039,665.589,396.433z
      M726.475,458.534
      c1.525,0.682,3.051,1.365,4.575,2.047c-5.113,11.299-9.625,22.922-15.488,33.817c-7.494,13.929-11.258,27.176-0.733,39.993
      c3.443-6.616,6.173-13.024,9.917-18.772c5.04-7.736,11.506-14.579,16.22-22.483c11.262-18.893,21.922-38.147,32.661-57.348
      c1.753-3.134,2.678-6.731,3.987-10.115c-3.657-1.07-7.558-3.555-10.932-2.976c-19.397,3.329-38.9,6.542-57.82,11.777
      c-4.776,1.32-8.306,9.524-11.004,15.229c-2.15,4.545-0.545,11.383-3.404,14.981c-11.978,15.072-3.324,26.755,6.188,42.711
      C710.118,489.47,718.297,474.003,726.475,458.534z
      M296.672,602.661c2.437,0.23,4.876,0.461,7.313,0.692
      c1.432-6.434,3.609-12.803,4.168-19.312c2.936-34.219,9.835-67.352,23.467-99.109c3.512-8.185,5.339-17.926,4.876-26.792
      c-0.75-14.399-3.837-28.699-6.342-42.971c-0.885-5.037-2.954-9.866-4.75-15.66c-36.418,26.513-40.636,43.714-27.03,100.868
      c21.387-18.978,11.861-41.602,6.635-64.375c13.163,11.358,18.239,25.757,13.793,41.988c-3.775,13.795-9.962,26.983-15.662,40.191
      C291.107,546.056,297.379,574.457,296.672,602.661z
      M329.995,349.721c1.822,0.747,3.642,1.496,5.462,2.242
      c4.783-5.633,9.471-11.353,14.366-16.887c42.201-47.711,44.011-61.471,13.809-114.55c-3.958-6.954-9.702-13.025-15.338-18.805
      c-1.524-1.564-5.683-0.561-8.631-0.735c0.438,2.377,0.624,4.833,1.35,7.118c6.486,20.374,13.067,40.718,19.873,61.855
      c-6.464,3.27-14.585,6.473-21.645,11.244c-5.507,3.721-13.734,9.382-13.75,14.204C325.438,313.507,328.255,331.616,329.995,349.721z
      M375.891,632.066c19.216-27.396,38.168-54.355,57.034-81.376c2.587-3.705,5.932-7.433,6.912-11.619
      c5.341-22.854,10.049-45.855,14.518-66.67C395.911,508.207,381.719,568.26,375.891,632.066z
      M209.073,328.201
      c10.242-24.822,18.17-44.519,26.535-64.031c2.697-6.289,5.206-15.701,10.083-17.504c25.651-9.48,21.59-35.223,31.813-53.907
      c-20.213,6.927-44.713,11.311-55.74,25.887c-13.538,17.895-18.013,43.926-22.052,67.194
      C197.636,297.802,205.016,311.406,209.073,328.201z
      M257.649,444.768c1.9,0.248,3.799,0.494,5.698,0.74
      c14.481-11.972,20.844-26.946,18.724-45.871c-0.684-6.1,0.865-12.789,2.87-18.707c1.01-2.986,5.319-4.855,8.135-7.229
      c2.157,2.863,5.568,5.479,6.187,8.643c1.034,5.285,0.282,10.916,0.282,16.402c9.702-6.481,21.423-14.923,21.24-23.101
      c-0.489-21.999-6.059-43.885-10.382-70.57c-18.659,25.335-34.299,46.57-50.196,68.153c14.922,7.262,14.397,8.048,10.189,21.4
      C265.224,411.05,261.819,428.029,257.649,444.768z
      M611.491,516.375c0.794,0.508,1.591,1.015,2.387,1.522
      c10.299-22.986,20.772-45.897,30.794-69.004c3.156-7.275,9.253-16.889,6.883-22.325c-10.212-23.411-23.072-45.666-34.722-67.873
      C615.019,412.284,613.255,464.329,611.491,516.375z
      M607.028,617.094c-0.468-2.222-0.938-4.443-1.408-6.665
      c-19.942-5.794-38.218,3.013-54.952,11.598c-32.375,16.604-63.102,36.396-95.163,53.653c-30.938,16.654-62.7,31.781-94.1,47.58
      c0.791,1.682,1.584,3.361,2.377,5.041C444.864,691.232,525.946,654.163,607.028,617.094z
      M507.128,505.762
      c-1.046-2.589-2.092-5.179-3.136-7.77c-12.732,5.589-30.612,7.713-37.161,17.456c-15.435,22.961-25.467,49.552-39.074,77.536
      c24.617-8.688,46.229-16.909,68.238-23.886c16.448-5.213,21.151-15.954,18.325-36.161c-8.187,4.033-14.812,6.843-20.965,10.455
      c-6.471,3.8-12.492,8.368-18.709,12.602c-0.817-1.278-1.634-2.557-2.451-3.835c2.987-10.181,5.974-20.362,8.92-30.409
      C489.182,516.792,498.156,511.276,507.128,505.762z
      M373.186,383.762c1.128,0.59,2.258,1.181,3.386,1.772
      c8.928-13.423,16.459-28.105,27.145-39.944c12.796-14.18,5.553-24.983-0.643-38.771c-17.116,22.168-31.498,43.808-48.964,62.573
      c-17.528,18.831-21.835,40.706-6.064,66.74c8.171-23.335,15.664-44.73,23.156-66.128c1.39,0.472,2.777,0.945,4.165,1.417
      C374.64,375.535,373.912,379.648,373.186,383.762z
      M208.422,560.68c1.711,0.232,3.422,0.464,5.134,0.694
      c1.319-4.067,2.704-8.116,3.946-12.208c3.681-12.106,5.029-25.625,11.423-36.059c13.681-22.33,4.473-46.742,9.094-69.724
      c0.655-3.256-5.134-10.887-8.263-11.073c-4.238-0.253-10.63,3.717-12.811,7.684c-4.086,7.438-7.917,16.113-8.152,24.38
      C207.88,496.454,208.422,528.574,208.422,560.68z
      M232.882,303.462c24.851,17.362,29.525,0.646,33.548-19.917
      c4.832,12.534,7.364,19.112,11.695,30.351c9.936-14.033,23.082-24.349,23.595-35.256c0.768-16.39-3.873-34.654-19.656-51.789
      c-4.489,11.631-6.984,19.625-10.615,27.062c-3.392,6.947-7.354,13.819-12.197,19.81
      C250.982,283.954,241.827,293.466,232.882,303.462z
      M396.721,504.818c46.688-40.18,70.822-89.063,61.601-125.62
      c-8.969,10.594-24.328,20.45-24.984,31.202c-1.473,24.138-17.324,39.792-25.687,59.583
      C403.087,480.782,400.528,492.428,396.721,504.818z
      M363.699,113.022c-3.068-0.638-6.135-1.275-9.203-1.913
      c-16.057,22.324-33.2,43.984-47.535,67.364c-4.522,7.373-1.526,19.355-1.971,29.228c2.354,0.63,4.707,1.259,7.06,1.89
      c17.704-24.692,36.049-48.967,52.572-74.427C367.901,130.111,364.188,120.52,363.699,113.022z
      M252.558,504.531
      c-27.794,56.438-27.162,48.08-9.121,73.576c13.165-5.709,25.834-11.199,40.85-17.709
      C282.246,539.067,262.257,525.389,252.558,504.531z
      M501.502,302.483c-13.655,15.135-33.527,88.455-30.465,111.841
      c20.729-13.415,16.823-36.105,15.639-57.945c1.722,0.06,3.442,0.12,5.163,0.18c2.194,6.435,4.389,12.867,7.65,22.424
      c5.934-10.391,14.715-18.634,14.018-25.976C511.864,335.748,505.694,318.921,501.502,302.483z
      M632.864,585.254
      c0.873,1.916,1.746,3.832,2.621,5.748c4.982-2.851,12.249-4.523,14.457-8.805c4.218-8.18,5.612-17.832,7.888-26.945
      c0.096-0.379-2.88-2.336-4.201-2.129c-11.074,1.739-25,0.314-32.511,6.542c-14.194,11.769-24.69,27.994-36.725,42.368
      c4.579-2.218,13.422-0.167,20.547-2.787c7.319-2.689,13.104-9.779,19.298-15.243c5.422-4.781,10.477-9.979,15.694-14.993
      c0.921,0.969,1.844,1.939,2.766,2.909C639.42,576.364,636.142,580.81,632.864,585.254z
      M229.623,385.125
      c1.781-0.196,3.56-0.391,5.341-0.586c9.834-15.031,20.164-29.777,29.124-45.312c2.035-3.529,1.148-12.122-1.608-14.286
      c-4.71-3.695-12.483-6.513-18.003-5.3c-5.588,1.227-12.184,6.87-14.437,12.223c-3.524,8.373-5.009,18.126-5.033,27.3
      C224.984,367.815,227.963,376.471,229.623,385.125z
      M715.883,300.161c-13.396,41.213-27.415,84.35-42.171,129.749
      C708.489,412.354,717.804,344.62,715.883,300.161z
      M410.496,752.476c-0.08,0.764-0.161,1.527-0.241,2.293
      c15.491,2.339,30.981,4.678,50.177,7.575c33.523-7.344,71.68-15.703,109.834-24.063c-0.426-1.976-0.85-3.953-1.273-5.931
      c-26.034,4.881-51.914,10.98-78.146,14.303C464.243,750.021,437.293,750.646,410.496,752.476z
      M352.586,263.124
      c-8.049-14.629-15.68-28.498-23.608-42.909c-15.055,18.148-20.417,40.718-10.321,47.498
      C329.072,274.706,341.883,278.006,352.586,263.124z
      M428.654,714.682c40.998-6.152,160.983-62.031,177.296-81.128
      C548.374,659.9,490.05,686.589,428.654,714.682z
      M558.575,252.565c22.404-21.378,15.494-35.743,0.118-51.172
      C538.949,230.025,538.733,239.81,558.575,252.565z
      M399.368,245.659c1.6-0.617,3.199-1.234,4.799-1.851
      c-4.606-28.69-20.669-52.957-36.148-78.22c-3.29,7.188-5.602,15.054-2.879,20.312C375.679,206.271,387.822,225.811,399.368,245.659z
      M332.01,651.259c-8.381,15.858-16.882,30.823-24.096,46.384c-1.813,3.908,0.5,9.731,0.917,14.674
      c4.78-1.986,10.66-2.818,14.158-6.15C342.238,687.831,343.679,678.152,332.01,651.259z
      M654.064,211.518
      c12.121-2.941,21.721-5.271,34.214-8.304c-4.907-13.452-9.711-26.62-14.515-39.789c-1.831,0.181-3.661,0.361-5.493,0.54
      C663.801,178.928,659.332,193.889,654.064,211.518z
      M718.47,556.12c-46.209-6.838-58.423-0.146-50.593,24.927
      C684.841,572.689,701.656,564.406,718.47,556.12z
      M259.827,593.429c-3.796-3.452-6.577-5.981-9.359-8.511
      c-1.166,0.923-2.331,1.846-3.495,2.769c8.729,13.457,17.461,26.915,26.96,41.561c3.203-5.58,5.437-9.337,7.539-13.165
      c6.911-12.587,0.548-33.96-9.798-31.451C268.317,587.122,264.631,589.86,259.827,593.429z
      M612.638,541.91
      c33.802-23.838,57.303-62.816,51.087-82.845C647.267,485.754,631.109,511.955,612.638,541.91z
      M484.398,292.258
      c18.789-22.985,15.515-66.969-4.909-76.23C481.096,240.987,482.686,265.672,484.398,292.258z
      M638.357,254.642
      c8.488-2.104,16.738-2.812,23.579-6.291c4.938-2.511,8.002-8.706,11.901-13.257c-4.072-3.159-8.504-9.232-12.132-8.785
      c-5.379,0.662-11.219,5.081-14.947,9.511C643.088,240.182,641.799,246.551,638.357,254.642z
      M279.53,455.894
      c-20.615,28.878-19.97,35.623,4.609,56.275C282.617,493.571,281.161,475.797,279.53,455.894z
      M465.232,334.813
      c-11.426-6.036-21.185-11.19-34.881-18.427c6.321,17.227,10.812,29.464,16.451,44.833
      C453.623,351.447,458.708,344.164,465.232,334.813z
      M676.724,498.326c-10.718,12.246-21.434,24.493-32.729,37.402
      C669.813,538.278,669.813,538.278,676.724,498.326z
      M191.024,572.243c0-22.947,0-44.592,0-67.683
      C174.959,545.109,171.613,558.629,191.024,572.243z
      M430.011,611.259c38.39,6.599,38.39,6.599,49.12-16.857
      C464.102,599.559,449.503,604.57,430.011,611.259z
      M308.263,670.988c2.786,0.216,5.571,0.429,8.355,0.645
      c1.225-21.655,2.449-43.309,3.674-64.964c-0.813-0.102-1.629-0.2-2.442-0.301C314.654,627.908,311.458,649.449,308.263,670.988z
      M580.604,248.125c-14.272,21.472-14.213,21.806,4.51,31.542C583.664,269.535,582.393,260.647,580.604,248.125z" />
</svg>
